const price = {
  car: {
    title: "Passenger car",
    price: 13500
  },
  van: {
    title: "Commercial transport / minibus / minivan",
    price: 16000
  },
  tir: {
    title: "Cargo transport",
    price: 32000
  }
};

export default price;
